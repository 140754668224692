/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

const OPERATOR_SETTINGS_PERMISSION = 'can_create_and_edit_bots';

export default IntercomRoute.extend({
  store: service(),
  intercomEventService: service(),
  titleToken: 'Operator Settings',
  analytics: {
    place: 'settings',
    section: 'operator',
  },
  appService: service(),
  permissionsService: service(),

  beforeModel(transition) {
    if (this.appService.app.canSeeNewIASettingsHub) {
      this.transitionTo('apps.app.settings.ai-automation.automation');
    }
    this.permissionsService.ensurePermissionWhenTransitioning(
      OPERATOR_SETTINGS_PERMISSION,
      transition,
      'apps.app.automation.basics',
    );
  },

  async model(params) {
    let appId = this.get('appService.app.id');

    return hash({
      identity: this.store.findRecord('operator-identity', '1'),
      messengerSettings: this.store.findRecord('messenger-settings/all', appId),
      conversationImports: this.store.findAll('conversation-import'),
      customBotsSettings: this.store.findRecord('operator/custom-bots-settings', appId),
      botInboxSettings: this.store.findRecord('operator-bot-inbox-settings', appId),
      aiAgentSettings: this.store.findRecord('ai-agent/settings', appId),
      aiAgentQuickReplySettings: this.store.findRecord(
        'operator-settings/ai-agent-quick-replies',
        appId,
      ),
    });
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.set('previewConfiguration', model.messengerSettings.get('previewConfiguration'));
  },

  actions: {
    refreshRoute() {
      this.refresh();
    },
    didTransition() {
      this._super();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'operator_settings',
      });
    },
  },
});
