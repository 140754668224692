/* RESPONSIBLE TEAM: team-purchase-experience */

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export enum CheckoutType {
  EarlyStagePostPurchase = 'early_stage_post_purchase',
  ACHPostPurchase = 'ach_post_purchase',
  ACHPostAnnualMigration = 'ach_post_annual_migration',
  DirectCheckoutPostPurchase = 'direct_checkout_post_purchase',
  DirectCheckoutPostAnnualMigration = 'direct_checkout_post_annual_migration',
  OneDollarTrialPostStart = 'one_dollar_trial_post_start',
}

export const DEFAULT_CHECKOUT_TYPE = CheckoutType.DirectCheckoutPostAnnualMigration;

export default class Success extends Route {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  beforeModel() {
    if (!this.appService.app.canUsePostCheckoutSuccessScreen) {
      this.router.transitionTo('apps.app.index');
    }
  }
}
