/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type IntlService from 'embercom/services/intl';
import type ContentImportService from 'embercom/services/content-import-service';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';

export default class KnowledgeHubRoute extends Route {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: any;
  @service declare router: any;
  @service declare intl: IntlService;
  @service declare helpCenterService: any;
  @service declare contentImportService: ContentImportService;

  get titleToken(): string {
    return this.intl.t('knowledge-hub.title');
  }

  async beforeModel() {
    if (this.appService.app.canUseKnowledgeHub || this.appService.app.canUseInboxCopilot) {
      if (!this.helpCenterService.fetchingSite) {
        await this.helpCenterService.forceFetchSite();
      }
      ConversationAttributeDescriptor.peekAllAndMaybeLoad();

      await Promise.all([
        this.knowledgeHubService.fetchKnowledgeUsageSummary(),
        this.contentImportService.fetchContentImportSources(),
        this.knowledgeHubService.fetchImportSources(),
        this.knowledgeHubService.fetchAvailableLocales(),
        this.knowledgeHubService.fetchFoldersOnce(),
        this.knowledgeHubService.fetchArticleCollections(),
      ]);
    } else {
      this.transitionTo('not-found', {});
    }
  }
}
